.button-rectangle-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 110px;
  height: 110px;
}


.rectangle-button {
  cursor: pointer;
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
}

.rectangle-button:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}