.order-list-wrapper {
  display: block;
  justify-content: center;

  .order-list {
    margin: auto;
    overflow: auto;

    .table-container {

      .form-control,
      select {
        width: 84%;
      }

      width: 100%;
      overflow-x: auto;

      table {
        width: 100%;
        table-layout: auto;
      }

      thead {
        background-color: #ddd;

        input,
        select {
          font-size: 16px;
          border-radius: 5px;
        }

        input:focus {
          border: 2px solid #f59b14;
        }

        tr:hover {
          background-color: #ddd;
          //cursor:;
        }

        tr {
          th:first-child {
            width: auto;
          }
        }
      }

      tr:nth-child(even) {
        background-color: #ddd;
      }

      tr:hover {
        background-color: #f59b14af;
      }

      th:nth-child(3) {
        width: 12%;
      }

      th:nth-child(5) {
        width: 6%;
      }

      th:nth-child(9) {
        width: 8%;
      }

      td {
        padding: 4px 6px;

        .order-edit {
          img {
            width: 25px;
            cursor: pointer;
          }
        }

        .order-relocate {
          img {
            width: 25px;
            cursor: pointer;
          }
        }
      }
    }

    .list-counter {
      padding-right: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #8a8a8a;
    }
  }
}

@media screen and (max-width: 1600px) {
  // .order-list-wrapper .order-list .table-container table {
  //     max-width: 98vw;
  //     display: flex;
  //     flex-direction: column;
  //     max-height: 60vh;
  //     overflow: scroll;
  // }
}