.validation-message-wrapper {
  position: relative;

  .error-text {
    display: inline-block;
    padding: 0 25px 0 2px;
    color: red;
    font-weight: bold;
    max-width: 280px;
  }
  .fa.fa-check-circle,
  .fa.fa-times-circle {
    font-size: 22px;
  }

  .fa.fa-check-circle {
    color: green;
    position: absolute;
    top: -38px;
    right: -25px;
  }

  .fa.fa-times-circle {
    color: red;
    vertical-align: top;
    padding: 1px 6px;
  }
}
