.decathlon-station-select {
    .station {
        border: 1px solid blue;
        border-radius: 8px;
        padding: 8px 16px;
        text-align: center;
        font-weight: bold;
        margin-bottom: 8px;
        font-size: 22px;
        cursor: pointer;
    }
}