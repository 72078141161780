.yesno-modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    direction: rtl;
}

.yesno-modal-container {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 400px;
    padding: 20px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    text-align: center;
    display: flex;
    flex-direction: column;
    gap: 15px;
}

.yesno-modal-title {
    font-size: 1.5rem;
    font-weight: bold;
    color: #333;
    margin-bottom: 0;
}

.yesno-modal-text {
    font-size: 1rem;
    line-height: 1;
    color: #333;

}

.yesno-modal-btns-wrapper {
    display: flex;
    justify-content: center;
    gap: 15px;
}

.yesno-modal-button {
    min-width: 100px;
    padding: 10px 15px;
    border-radius: 4px;
    transition: background-color 0.3s ease;
}

.yesno-modal-button.yes {
    background-color: #28a745;
    color: white;
}

.yesno-modal-button.yes:hover {
    background-color: #218838;
}

.yesno-modal-button.no {
    background-color: #6c757d;
    color: white;
}

.yesno-modal-button.no:hover {
    background-color: #545b62;
}

@media (max-width: 480px) {
    .yesno-modal-container {
        width: 95%;
    }

    .yesno-modal-btns-wrapper {
        flex-direction: column;
        gap: 10px;
    }

    .yesno-modal-button {
        width: 100%;
    }
}