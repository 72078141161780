.content {
  width: 100%;
}

.search-wrapper {
  width: 100%;

  .autocomplete-wrapper {
    margin-left: 16px;
    min-width: 200px;
    height: 50px;
  }
  .search-btn-wrapper {
    height: 50px;
  }
}

.search-orders-filterer {
  display: block;
  margin: 18px 0 10px 0;
  width: 100%;

  .search-input,
  .date-picker-input {
    font-size: 16px;
    min-width: 80px;
    max-width: 150px;
    max-height: 30px;
    margin: 0px 0 0px 24px;
    box-shadow: 0 0 #8a8a8a;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
    display: inline-flex;
  }

  .date-picker-input {
    padding: 7px 6px;
  }

  .date-picker-label {
    top: -20px;
  }

  .ss-group .ss-input {
    font-size: 16px;
    min-width: 140px;
    padding: 0 12px;
    margin: 10px 12px 0px 15px;
    box-shadow: 0 0 #8a8a8a;
    border: 1px solid rgba(0, 0, 0, 0.2);
    border-radius: 6px;
  }

  .search-input:hover,
  .date-picker-input:hover,
  .date-picker-input:focus,
  .ss-group .ss-input:hover {
    border: 1px solid #29a7df;
  }

  .mul-selvalpassive-itm-content {
    font-size: 16px;
    text-align: right;
    padding: 0 6px;
    align-self: center;
    flex: 1;
  }

  .line-wrapper {
    display: flex;
    width: 100%;
    align-items: center;
    margin-bottom: 12px;

    .input-field {
      margin: 0;
      height: 50px;
    }
  }

  .label {
    min-width: 65px;
    padding-right: 6px;
    padding-top: 16px;
    align-self: start;
    text-align: left;
    color: #8a8a8a;
  }

  .label .clear-btn {
    cursor: pointer;
  }

  .select-wrapper {
    padding: 10px 12px 0px 15px;
    max-width: 400px;
    min-width: 200px;

    .autocomplete-select__control {
      direction: ltr;
      border-radius: 6px;
      box-shadow: 0 0 #8a8a8a;
    }

    .autocomplete-select__control:hover {
      border: 1px solid #29a7df;
    }

    .autocomplete-select__value-container {
      direction: rtl;
    }

    .autocomplete-select__single-value {
      font-size: 16px;
      color: #8a8a8a;
    }

    .autocomplete-select__menu-list {
      padding: 2px 6px;
    }

    .autocomplete-select__option {
      font-size: 12px;
      border-radius: 6px;
    }
  }

  .multi-autocomplete-wrapper .autocomplete-select__placeholder {
    font-size: 16px;
    font-family: Heebo;
  }

  .search-btn-wrapper .disabled {
    background-color: #d3d3d3;
    color: #a0a0a0;
    cursor: not-allowed;
  }

  .validation-message {
    height: 18px;
  }

  .validation-message-wrapper {
    margin: 5px 0 0 0;
    font-size: 10px;
    position: absolute;
    background: #eeeeee;
  }

  .validation-message-wrapper .fa {
    position: absolute;
    font-size: 15px;
  }

  .validation-message-wrapper .fa.fa-check-circle {
    right: -21px;
    top: -30px;
  }
}
