.changePassword {
  .input-wrapper {
    display: flex;
    justify-content: left;
  }

  .eye-icon {
    position: absolute;
    width: 26px;
    cursor: pointer;
    padding: 16px 20px;
  }

  .buttons-wrapper {
    display: flex;
    justify-content: center;
    align-items: center;
    margin-top: 2rem;
  }

  input {
    direction: ltr;
    text-align: right;
  }
}