//body {
    //align-items: center;
    //display: flex;
    //height: 100vh;
    //justify-content: center;
    //margin: 0;
  //}
  .open-menu-btn{
      cursor:pointer;
        svg {
            height: 80px;
            position: absolute;
            width: 80px;
        }
        .plate {
            height: 80px;
            width: 80px;
        }
        //.burger {
            //filter: url(#gooeyness);
        //}
        .x {
            transform: scale(0);
            transition: transform 400ms;
        }
        .line {
            fill: none;
            stroke: #0b71b8;;
            stroke-width: 6px;
            stroke-linecap: round;
            stroke-linejoin: round;
            transform-origin: 50%;
            transition: stroke-dasharray 500ms 200ms, stroke-dashoffset 500ms 200ms, transform 500ms 200ms;
        }
        .x .line {
            stroke-width: 5.5px;
        }
        
        .active .line {
            transition: stroke-dasharray 500ms, stroke-dashoffset 500ms, transform 500ms;
        }

        .active .x {
            transform: scale(1);
            transition: transform 400ms 350ms;
        }
        
        
        /* Die vierte teller */
        .plate4 .x {
            transition: transform 400ms;
        }
        .plate4 .line {
            transform-origin: 50%;
            transition: transform 400ms 100ms;
        }
        .active.plate4 .line {
            transition: transform 400ms;
        }
        .active.plate4 .line1 {
            transform: translateX(18px) translateY(-3px) rotate(-45deg) scale(.7);
        }
        .active.plate4 .line2 {
            transform: translateX(-18px) translateY(-3px) rotate(45deg) scale(.7);
        }
        .active.plate4 .line3 {
            transform: translateY(0px) rotate(45deg) scale(.7);
        }
        .active.plate4 .line4 {
            transform: translateY(0px) rotate(-45deg) scale(.7);
        }
        .active.plate4 .line5 {
            transform: translateX(18px) translateY(3px) rotate(45deg) scale(.7);
        }
        .active.plate4 .line6 {
            transform: translateX(-18px) translateY(3px) rotate(-45deg) scale(.7);
        }
        .active.plate4 .x {
            transition: transform 400ms 100ms;
            transform: scale(1);
        }

  }
  
  
  @media (max-width: 640px) {
    .plates {
      width: 320px;
    }
  }
  