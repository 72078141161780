.failedSmsScreen {

    h1 {
        font-size: 24px;
        margin-bottom: 20px;
    }

    .sms-table {
        width: 100%;
        border-collapse: collapse;
        margin-top: 20px;
        font-size: 14px;

        th,
        td {
            padding: 10px;
            border: 1px solid #ddd;
            text-align: right;
        }

        td:nth-child(1) {
            width: 10%;
            direction: ltr;
        }

        td:nth-child(2) {
            width: 10%;
        }

        td:nth-child(4) {
            width: 7%;
        }

        th {
            background-color: #f2f2f2;
            font-weight: bold;
        }

        tr:nth-child(even) {
            background-color: #f2f2f2;
        }

        tr:hover {
            background-color: #e0e0e0;
        }

        .status {
            position: relative;
            display: flex;
            align-items: center;
            cursor: pointer;

            img {
                margin: 0 8px 0 0;
                width: 16px;
                height: 16px;
            }
        }

        .tooltip-bubble {
            position: absolute;
            top: -50px;
            left: 50%;
            transform: translateX(-50%);
            background-color: #333;
            color: white;
            padding: 10px;
            border-radius: 8px;
            width: 200px;
            text-align: center;
            opacity: 0;
            visibility: hidden;
            transition: opacity 0.3s, visibility 0.3s;
            z-index: 1;
            box-shadow: 0 2px 5px rgba(0, 0, 0, 0.3);
        }

        .tooltip-arrow {
            position: absolute;
            top: 100%;
            left: 50%;
            transform: translateX(-50%);
            border: solid transparent;
            border-width: 6px;
            margin-left: -6px;
            border-top-color: #333;
            visibility: hidden;
        }

        .status:hover .tooltip-bubble {
            opacity: 1;
            visibility: visible;
        }

        .status:hover .tooltip-arrow {
            visibility: visible;
        }
    }

    .pagination {
        margin-top: 10px;
        text-align: center;

        button {
            margin: 0 5px;
            padding: 5px 10px;
            border: 1px solid #ddd;
            cursor: pointer;
            background-color: transparent;
            outline: none;
            font-size: 14px;

            &.active {
                background-color: #007bff;
                color: white;
            }
        }
    }
}