.noti-modal-wrap {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;

    .noti-modal-backdrop {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: rgba(0, 0, 0, 0.5);
        z-index: -1;
    }

    .noti-modal-container {
        background: #fff;
        border-radius: 8px;
        padding: 20px;
        max-width: 400px;
        width: 90%;
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.2);
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;


        .noti-modal-header {
            font-size: 18px;
            font-weight: bold;
            margin-bottom: 10px;
        }

        .noti-modal-content-wrapper {
            font-size: 14px;
            margin-bottom: 20px;
        }

        .btn-holder {
            .noti-modal-button {
                padding: 8px 16px;
                background-color: #007bff;
                color: #fff;
                border: none;
                border-radius: 4px;
                cursor: pointer;
                transition: background-color 0.3s;

                &:hover {
                    background-color: #0056b3;
                }
            }
        }
    }
}