.input-field {
  margin-bottom: 10px;
  height: 68px;
  //position: relative;
  .input-wrapper {
    display: flex;
    justify-content: left;
  }
  label {
    display: flex;
    font-weight: bold;
    font-size: 18px;
    color: #555;
    align-items: center;
  }

  input {
    width: 80vw;
    max-width: 400px;
    font-size: 20px;
    padding: 8px;
    border-radius: 5px;
    margin: 4px 4px 4px 4px;
  }
}
