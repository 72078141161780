.ss-container.new-user {
  padding: 20px;
  border: 1px solid #ccc;
  border-radius: 5px;

  h1 {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .section-info-row {
    display: flex;
    flex-direction: column;
    margin-bottom: 15px;

    label {
      font-size: 16px;
      color: #333;
    }

    input {
      padding: 10px;
      min-width: 300px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: border-color 0.3s;

      &:focus {
        border-color: #3498db;
      }
    }

    .validation-message {
      margin-top: 5px;
      color: #e74c3c;
      font-size: 14px;
    }
  }

  .btn-holder {
    text-align: center;
    margin-top: 20px;

    .button {
      background-color: #3498db;
      color: #ffffff;
      padding: 10px 20px;
      border: none;
      border-radius: 5px;
      cursor: pointer;
      font-size: 16px;
      transition: background-color 0.3s, color 0.3s;

      &:hover {
        background-color: #2980b9;
      }
    }
  }

  .checkbox-group {
    display: flex;
    justify-content: space-evenly;

    label {
      display: flex;
      margin-bottom: 10px;
      font-size: 16px;
      cursor: pointer;

      input[type='checkbox'] {
        margin-right: 10px;
        width: 20px;
        height: 20px;
        cursor: pointer;
      }

      &:hover {
        color: #007bff;
      }
    }

    input[type='checkbox']:checked+label {
      font-weight: bold;
      color: #007bff;
    }
  }
}