.app-wrapper {
  margin: 0;
  min-height: 100vh;
  direction: rtl;
  color: #383838;
  background-color: #eee;
  font-size: 16px;

  .header-wrapper {
    margin: 0;
    position: fixed;
    padding: 20px;
    height: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100vw;
    background-color: #eee;
    z-index: 3;

    .app-version {
      display: inline-block;
      position: fixed;
      top: 74px;
      left: 56px;
    }

    .logo-wrapper {
      display: inline-block;
      position: fixed;
      top: 0;
      left: 0;

      img {
        height: 100px;
        padding: 0;
        border-top: 0;
        border-bottom: 1px;
      }
    }

    .welcome {
      display: inline-block;
      position: fixed;
      right: 8em;
    }
  }

  .content-wrapper {
    height: calc(100% - 40px);
    padding: 6em 2em 2em 2em;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    .content {
      display: flex;
      justify-content: center;
      align-items: center;
    }
  }
}