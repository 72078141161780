.update-order {
    padding: 20px;
    border: 1px solid #ccc;
    border-radius: 5px;

    .select-status {
        display: flex;
        justify-content: left;
        padding: 0px 2px;

        .select-wrapper {
            width: 422px;

            div:first-child {
                width: 100%;
            }
        }

    }

    .btn-header-holder {
        display: flex;
        justify-content: space-evenly;
        margin: 0 0px 10px 0;
        gap: 10px;
        flex-wrap: wrap;
        max-width: 32rem;

        div {
            color: #29a7df;
            cursor: pointer;
            font-size: 16px;
            font-weight: 600;
            text-decoration: underline;
        }
    }


    .select-wrapper {
        display: flex;
        justify-content: left;

        .ss-input {
            margin: 4px 4px 4px 0px;
        }
    }

    .buttons-wrapper {
        display: flex;
        justify-content: center;
        margin-top: 2rem;

        .back-btn {
            padding: 0 4rem;
            margin-right: 2rem;
        }
    }
}

.error {
    color: red;
    font-size: 12px;
    margin: 0 0 10px 0;
}