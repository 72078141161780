.order-relocate-wrapper {
    padding: 28px;
    background-color: #f4f4f4;
    border-radius: 8px;

    .order-details {
        margin-bottom: 20px;
    }

    .details-block {
        display: flex;
        justify-content: space-between;
        margin-bottom: 10px;
        font-size: 16px;
        line-height: 1.5;

        .details-label {
            font-weight: bold;
        }

        .details-value {
            text-align: right;
            font-size: 18px;
            color: #29a7df;
            font-weight: bold;
        }
    }

    .spinner-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        height: 100px;
    }

    .form-inline {
        margin-top: 20px;

        .base-select {
            margin-right: 10px;
            margin-bottom: 15px;
        }

        .validation-message {
            color: red;
            margin-bottom: 10px;
        }

        .btn-holder {
            display: flex;
            justify-content: space-between;
            margin: 1.5rem 0 0 0;

            .btn-relocate {
                background-color: #4caf50;
                color: white;
                border: none;
                cursor: pointer;
            }

            .btn-back {
                color: white;
                border: none;
                cursor: pointer;
            }
        }

    }
}