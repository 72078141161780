.order-transfer-modal {
    position: fixed;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    background-color: #ffffff;
    border: 1px solid #ddd;
    border-radius: 8px;
    padding: 20px;
    max-width: 400px;
    width: 90%;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    z-index: 1000;
    text-align: center;
    font-family: Arial, sans-serif;

    h2 {
        margin-bottom: 16px;
        font-size: 1.5rem;
        color: #333;
    }

    .current-station {
        margin-bottom: 16px;
        font-size: 1rem;
        color: #666;

        span {
            font-weight: bold;
            color: #333;
        }
    }

    .station-selector {
        margin-bottom: 16px;

        .react-select__control {
            border-radius: 4px;
            border: 1px solid #ccc;

            &:hover {
                border-color: #888;
            }
        }

        .react-select__menu {
            border-radius: 4px;
            box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
        }

        .react-select__option--is-focused {
            background-color: #f0f0f0;
        }

        .react-select__option--is-selected {
            background-color: #007bff;
            color: #fff;
        }
    }

    .btn-holder {
        display: flex;
        justify-content: space-between;

        button {
            padding: 8px 16px;
            border: none;
            border-radius: 4px;
            font-size: 1rem;
            cursor: pointer;
            transition: background-color 0.2s;

            &:first-child {
                background-color: #007bff;
                color: #fff;

                &:hover {
                    background-color: #0056b3;
                }
            }

            &:last-child {
                background-color: #f8f9fa;
                color: #333;
                border: 1px solid #ccc;

                &:hover {
                    background-color: #e2e6ea;
                }
            }
        }
    }
}

.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    z-index: 999;
}