.scanOrder {
    font-size: 20px;

    .package {
        margin-bottom: 12px;
    }

    input {
        font-size: 24px;
    }

    .external-number {
        font-weight: bold;
    }

    .label {
        padding: 4px 8px;
    }

    .error {
        color: red;
        font-size: 22px;
        text-align: center;
        margin-top: 28px;
    }
}