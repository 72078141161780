.login {
    .buttons-wrapper {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-top: 2rem;
    }
}

.login-error {

    color: #e74c3c;
    border-radius: 5px;
    text-align: center;

    .error-title {
        font-size: 26px;
        margin-bottom: 10px;
    }

    .error-text {
        font-size: 18px;
    }
}