.user-list-wrapper {
  display: block;
  justify-content: center;

  .user-list {
    width: 90vw;
    margin: auto;
    overflow: auto;

    .table-container {
      table {
        width: 90vw;
      }

      thead {
        background-color: #ddd;

        input,
        select {
          font-size: 16px;
          border-radius: 5px;
        }

        input:focus {
          border: 2px solid #f59b14;
        }

        tr:hover {
          background-color: #ddd;
        }

        tr {
          th:first-child {
            width: 23px;
          }
        }

        th input {

          width: 8rem;
        }

        th:nth-child(1) {
          min-width: 6rem;
        }
      }

      tr:nth-child(even) {
        background-color: #ddd;
      }

      tr:hover {
        background-color: #f59b14af;
      }

      td {
        padding: 4px 6px;
        width: 0px;
      }

      td .blue {
        color: #14aef5;
        font-size: 26px;
        padding: 0 24px;
      }

      td .orange {
        color: #f53614;
        font-size: 26px;
        padding: 4px 24px;
      }

      td .green {
        color: #0cad19;
        font-size: 26px;
        margin-right: 28px;
      }
    }

    .list-counter {
      padding-right: 2px;
      font-size: 16px;
      font-weight: bold;
      color: #8a8a8a;
    }

    .active {
      color: rgb(51, 172, 67);
      font-size: 26px;
    }

    .deleted {
      color: rgb(216, 38, 7);
      font-size: 26px;
    }

    .btn-delete {
      text-align: center;
      padding: 4px 0px;
      cursor: pointer;
    }

    .btn-delete:hover {
      background-color: red;
      border: 1px solid red;
    }

    .btn-reset-pass {
      text-align: center;
      padding: 4px 0px;
      cursor: pointer;
    }
  }
}