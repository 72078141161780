.TableContainer {

  .table-container {
    margin: 1rem 0;

    .pagination {
      display: flex;
      align-items: center;
      margin-bottom: 1.5rem;

      button {
        margin: 0 0.1rem;
        padding: 0.2rem 0.5rem;
        background-color: #007bff;
        color: white;
        border: none;
        border-radius: 4px;
        cursor: pointer;
        font-size: 1rem;

        &:hover {
          background-color: #0056b3;
        }

        &:disabled {
          background-color: #d6d6d6;
          cursor: not-allowed;
        }
      }

      .export-btn {
        margin-right: 1rem;
      }

      span {
        display: flex;
        justify-content: center;
        width: 6rem;
        margin: 0 0.1rem;
        font-size: 1rem;
        color: #333;
      }

      .form-select {
        max-width: 10rem;
        margin-right: 1rem;
        padding: 0.2rem;
        font-size: 1rem;
        border-radius: 4px;
        cursor: pointer;
        color: #333;

        &:hover {
          border-color: #007bff;
        }
      }
    }

    .scrolled-wrapper {
      overflow-x: auto;
      margin-top: 1rem;
      border: 1px solid #ddd;
      border-radius: 8px;
      box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);

      table {
        width: 100%;
        border-collapse: collapse;
        font-size: 0.875rem;

        th,
        td {
          padding: 1rem;
          text-align: right;
          border-bottom: 1px solid #f0f0f0;
        }

        th {
          direction: rtl;
          background-color: #f4f6f9;
          color: #007bff;
          font-weight: 600;
          text-transform: uppercase;
          cursor: pointer;
        }

        tbody tr {
          &:hover {
            background-color: #f9f9f9;
          }
        }

        td {
          color: #333;
        }
      }
    }
  }
}