.create-order {
  padding: 40px;
  border: 1px solid #ccc;
  border-radius: 5px;

  .title {
    font-size: 24px;
    margin-bottom: 20px;
    color: #333;
  }

  .input-field {
    margin-bottom: 15px;
    height: 68px;
    input {
      padding: 10px;
      font-size: 16px;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: border-color 0.3s;

      &:focus {
        border-color: #3498db;
      }
    }

    .validation-message {
      margin-top: 5px;
      color: #e74c3c;
      font-size: 14px;
    }
  }

  .station-selector {
    margin: 0 4px 0px 3px;
  }

  .station-number-error {
    display: inline-block;
    padding: 0 0px 18px;
    color: red;
    font-weight: bold;
    font-size: 14px;

    .fa-times-circle {
      font-size: 22px;
      margin-left: 6px;
    }
  }

  .buttons-wrapper {
    text-align: center;
    margin-top: 20px;
    display: flex;
    justify-content: center;

    .fileInputButton {
      display: inline-block;
      padding: 10px 14px;
      color: #fff;
      background-color: #1076ba;
      border: 1px solid #1076ba;
      border-radius: 5px;
      cursor: pointer;
      text-align: center;
      margin: 0 5px;
      font-size: 16px;
      line-height: 1;
    }
  }

  .message {
    margin-top: 20px;
    text-align: center;
    color: #333;

    &.error {
      color: #e74c3c;
    }
  }
}
