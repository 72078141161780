.modal-overlay {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    z-index: 1000;
    direction: rtl;
}

.modal-container {
    background-color: white;
    border-radius: 8px;
    width: 90%;
    max-width: 800px;
    max-height: 80vh;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
}

.modal-content {
    display: flex;
    flex-direction: column;
    height: 100%;
}

.modal-header {
    padding: 15px;
    background-color: #f8f9fa;
    border-bottom: 1px solid #e9ecef;

    .title {
        font-size: 1.2rem;
        font-weight: bold;
    }

    .order-number {
        color: #6c757d;
        font-size: 0.9rem;
        margin-top: 5px;
    }
}

.modal-body {
    flex-grow: 1;
    overflow-y: auto;
    padding: 15px;
}

.modal-footer {
    padding: 15px;
    background-color: #f8f9fa;
    border-top: 1px solid #e9ecef;
    display: flex;
    justify-content: flex-start;
}


@media (max-width: 600px) {
    .modal-container {
        width: 95%;
        margin: 0 10px;
    }
}