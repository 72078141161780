.menu-item{
    
    height: 50px;
    padding: 0 12px;
    cursor: pointer;
    border-right: solid 8px hsl(36, 92%, 52%);
    
    border-bottom: solid 1px #ccc;
    background-color: hsl(0, 5%, 96%);
    color: hsl(36, 92%, 52%);
    font-size: 16px;
    font-weight: bold;

    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
}



.menu-item:hover{
    background-color: hsl(36, 92%, 52%);
    color: #FFF;
}