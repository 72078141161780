.item-wrapper {
  display: flex;
  text-align: center;
  padding: 1px;
  flex: 1;
  justify-content: center;
  align-items: center;
  border-bottom: 1px solid #e5e5e5;
  background-color: rgba(244, 244, 244, 0.3);
  color: rgba(0, 0, 0, 1);
  font-size: 16px;
}

.item-wrapper:hover {
  color: rgba(0, 0, 0, 1);
  font-weight: bold;
  background-color: #e5e5e5;
  background-color: rgba(22, 119, 161, 0.05);
}

.item-last {
  /* border-bottom: rebeccapurple solid 1px; */
}

.lockers-btn,
.send-sms-btn,
.edit-btn {
  font-size: 16px;
  border-radius: 10px;
  padding: 3px 16px;
  font-weight: 400;
  letter-spacing: 1px;
  background: none;
  border: solid rgba(0, 0, 0, 0.5) 1px;
  color: rgba(0, 0, 0, 0.5);
}

.item-wrapper:hover .lockers-btn .item-wrapper:hover .send-sms-btn,
.item-wrapper:hover .edit-btn {
  border: solid rgba(0, 0, 0, 1) 1px;
  color: rgba(0, 0, 0, 1);
}

.item-wrapper:hover .lockers-btn:hover,
.item-wrapper:hover .send-sms-btn:hover,
.item-wrapper:hover .edit-btn:hover {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.item-wrapper:hover .lockers-btn:active,
.item-wrapper:hover .send-sms-btn:active,
.item-wrapper:hover .edit-btn:active {
  border: solid #29a7df 1px;
  background-color: #29a7df;
  color: #fff;
}

.clickable {
  cursor: pointer;
}
.circle {
  display: inline-block;
  border-radius: 20px;
  padding: 5px 5px 2px 4px;
  width: 25px;
  height: 25px;
  align-items: center;
}

.circle.version-1 {
  color: #29a7df;
  background-image: radial-gradient(white, white, #29a7df);
}

.circle.version-2 {
  color: #fda73a;
  background-image: radial-gradient(white, white, #fda73a);
}
.circle.version-3 {
  color: #be78c0;
  background-image: radial-gradient(white, white, #be78c0);
}
.circle.version-4 {
  color: #f05f6b;
  background-image: radial-gradient(white, white, #f05f6b);
}

.circle.broken {
  background-color: rgba(0, 0, 0, 0.05);
}
.circle.empty {
  color: rgb(51, 172, 67);
}

.station-alive {
  color: rgb(51, 172, 67);
  font-size: 24px;
}
.station-down {
  color: rgb(216, 38, 7);
  font-size: 24px;
}

.circle.lockers {
  border: solid 1px rgba(0, 0, 0, 0.1);
  background-image: radial-gradient(white, white, rgba(0, 0, 0, 0.2));
}

.btn-status{
width: 60px;
}
